html, body {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
  }
  
  .verify-home-container {
    display: flex;
  }
  
  .menu-container {
    width: 60px;
    height: 100vh;
    background-color: #2A5080;
    color: #fff;
    padding: 20px 10px;
    box-sizing: border-box;
    position: fixed;
    transition: width 0.3s, border-radius 0.3s;
    border-top-right-radius: 30px;
    border-bottom-right-radius: 30px;
    overflow: hidden;
    cursor: pointer;
  }
  
  .menu-container.expanded {
    width: 200px;
    border-radius: 0;
  }
  
  .logo-container {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .app-logo {
    width: 30px;
    height: 30px;
    margin-right: 10px;
  }
  
  .app-name {
    font-size: 1.5rem;
  }
  
  .menu-options {
    margin-top: 20px;
  }
  
  .menu-list {
    list-style-type: none;
    padding: 0;
  }
  
  .menu-item {
    padding: 20px 0;
    cursor: pointer;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  .menu-item:hover {
    background-color: #555;
  }
  
  .menu-item.logout {
    color: white;
  }
  
  .main-content {
    flex: 1;
    padding: 20px;
    margin-left: 60px;
    transition: margin-left 0.3s ease;
  }
  
  .main-content.menu-expanded {
    margin-left: 200px;
  }
  
  .top-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .icons {
    display: flex;
    gap: 10px;
  }
  
  .icon {
    font-size: 24px;
    cursor: pointer;
  }
  
  .icon:hover {
    color: #007bff;
  }
  
  .welcome {
    font-size: 24px;
    margin-bottom: 20px;
    margin-top: 10px;
  }
  
  .approve-button {
    background-color: #4CAF50; /* Green */
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
  }
  
  .reject-button {
    background-color: #f44336; /* Red */
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
  }
  
  .approve-button:hover {
    background-color: #45a049; /* Darker green */
  }
  
  .reject-button:hover {
    background-color: #e60000; /* Darker red */
  }
  
  .form-actions {
    display: flex;
    gap: 10px;
  }

  .form-details {
    margin: 20px 15px;
  }

  .form-details h2{
    margin-top: 40px;
  }
  
  .row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  
  .row p {
    width: 48%; /* This will ensure two columns per row */
    margin: 0;
    padding: 0;
  }
  
  .approved-status {
    color: green;
    font-weight: bold;
    text-align: center;
  }
  
  .rejected-status {
    color: red;
    font-weight: bold;
    text-align: center;
  }
  