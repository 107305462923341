html, body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  font-family: Arial, sans-serif;
}

/* Container for the Student Verification page */
.student-verification-container {
  display: flex;
  height: 100vh;
  width: 100%;
  overflow: hidden;
}

/* Sidebar/menu container */
.menu-expanded .menu-container {
  width: 200px;
}

.menu-container {
  width: 60px;
  background-color: #2A5080;
  color: #fff;
  padding: 20px 10px;
  box-sizing: border-box;
  position: fixed;
  height: 100%;
  transition: width 0.3s, border-radius 0.3s;
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
  overflow: hidden;
  cursor: pointer;
}

/* Main content area */
.main-content {
  flex: 1;
  padding: 20px;
  margin-left: 60px;
  transition: margin-left 0.3s ease;
  overflow-y: auto;
}

.main-content.menu-expanded {
  margin-left: 200px;
}

/* Top bar (header) */
.top-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  border-bottom: 1px solid #ddd;
  padding-bottom: 10px;
}

.welcome {
  font-size: 24px;
  color: #333;
}

.icons {
  display: flex;
  gap: 10px;
}

.icon {
  font-size: 24px;
  cursor: pointer;
  color: #555;
}

.icon:hover {
  color: #007bff;
}

/* Dropdown menu styling */
.dropdown-menu {
  margin-bottom: 20px;
}

.dropdown-menu select {
  padding: 10px;
  font-size: 16px;
  border-radius: 4px;
  border: 1px solid #ccc;
}

/* Table styling */
table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

table th, table td {
  padding: 12px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

table th {
  background-color: #f4f4f4;
}

table tr:hover {
  background-color: #f1f1f1;
}

button {
  padding: 8px 12px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

button:hover {
  background-color: #0056b3;
}

/* Message for no forms */
p {
  font-size: 18px;
  color: #666;
}
