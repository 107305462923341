html, body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
}

.institute-terms {
  display: flex;
}

.menu-container {
  width: 60px;
  height: 100vh;
  background-color: #2A5080;
  color: #fff;
  padding: 20px 10px;
  box-sizing: border-box;
  position: fixed;
  transition: width 0.3s, border-radius 0.3s;
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
  overflow: hidden;
  cursor: pointer;
}

.menu-container.expanded {
  width: 200px;
  border-radius: 0;
}

.main-content {
  flex: 1;
  padding: 20px;
  margin-left: 60px;
  transition: margin-left 0.3s ease;
}

.main-content.menu-expanded {
  margin-left: 200px;
}

.top-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.icons {
  display: flex;
  gap: 10px;
}

.icon {
  font-size: 24px;
  cursor: pointer;
}

.icon:hover {
  color: #007bff;
}

.welcome {
  font-size: 24px;
  margin-bottom: 10px;
}

.terms-box {
  max-height: 550px; /* Adjust height as needed */
  overflow-y: auto;
  padding: 40px;
  border: 1px solid #ddd;
  border-radius: 5px;
  background-color: #f9f9f9;
  margin-bottom: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.terms-box h2,
.terms-box h3 {
  margin-top: 20px;
}

.terms-box p {
  margin-bottom: 15px;
  line-height: 1.6;
}

.ok-button {
  display: block;
  width: 100px;
  margin: 0 auto;
  padding: 10px;
  background-color: #2A5080;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.ok-button:hover {
  background-color: #1e3964;
}
