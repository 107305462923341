
html, body {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 200%;
    
  }

.edituser-container {
    width: 100%;
    max-width: 600px;
    margin: auto;
    padding: 30px;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    background-color: #e9ecf1;
    border: 1px solid #756f6f;
    margin-top: 30px;
    margin-bottom: 50px;
    
}

.edituser-container h1 {
    text-align: center;
    margin-bottom: 40px;
    font-size: 20px;
    font-family: 'Inter',sans-serif;
}

.edituser-form {
    display: flex;
    flex-direction: column;
   
   
    
}

.form-group {
    margin-bottom: 15px;
    margin-top: 10px;
}

.form-group label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
}

.form-group input[type="text"],
.form-group select {
    width: 100%;
    padding: 8px;
    border-radius: 4px;
    border: 1px solid #413d3d;
    box-sizing: border-box;
}

button [type="submit1"] {
    padding: 10px 20px;
    font-size: 16px;
    font-weight: bold;
    color: #fff;
    background-color: #1f4670;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    align-self: flex-start;
    margin-top: 30px;
    margin-left: 250px;
    
}

button:hover {
    background-color: #0056b3;
}

.error-message {
    color: red;
    font-size: 14px;
    margin-top: 10px;
    text-align: center;
}

.profile-img-container {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
}

.profile-img-wrapper {
    position: relative;
    display: inline-block;
}

.profile-img {
    width: 150px; /* Increased width */
    height: 150px; /* Increased height */
    border-radius: 50%;
    object-fit: cover;
    border: 2px solid #161718;
}

.profile-img-placeholder {
    width: 150px; /* Increased width */
    height: 150px; /* Increased height */
    border-radius: 50%;
    background-color: #e0e0e0;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #a0a0a0;
    font-size: 18px; /* Slightly larger font size for better visibility */
}

.add-button {
    position: absolute;
    bottom: 10px; /* Adjusted to fit within the larger profile image */
    right: 10px; /* Adjusted to fit within the larger profile image */
    width: 20px; /* Increased size */
    height: 20px; /* Increased size */
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: #28a745;
    color: white;
    font-size: 15px; /* Adjusted font size for better visibility */
    cursor: pointer;
    text-align: center;
    line-height: 1;
}

.add-button:hover {
    background-color: #218838;
}

.file-input {
    display: none;
}

.update-button {
    background-color: #007bff; /* Button background color */
    color: white; /* Button text color */
    padding: 10px 20px; /* Padding for the button */
    border: none; /* Remove default border */
    border-radius: 5px; /* Rounded corners */
    cursor: pointer; /* Pointer cursor on hover */
    font-size: 16px; /* Font size */
    transition: background-color 0.3s ease; /* Smooth background color transition */
    width: 50%;
   margin-left: 140px;
  }
  
  .update-button:hover {
    background-color: #0056b3; /* Background color on hover */
  }

 