.form-containerr {
  padding: 20px;
  width: 1070px;
  margin: 0 auto;
  background-color: #ffffff;
  border: 1px solid #868cb1;
  border-radius: 5px;
}

.form-containerr h2 {
  margin-top: 20px;
  text-align: left;
  margin-bottom: 20px;
}

.form-section {
  margin-bottom: 20px;
}

.form-row {
  margin-bottom: 10px;
}

.form-row-inline {
  display: flex;
  justify-content: space-between;
}

.form-row-inline .form-row {
  flex: 1;
  margin-right: 30px;
}

.form-row-inline .form-row:last-child {
  margin-right: 0;
}

label {
  display: block;
  margin-bottom: 5px;
  margin-left: 10px;
}

input[type="text"],
input[type="file"],
input[type="email"],
input[type="date"],
textarea,
select {
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  margin-left: 10px;
}

input [type="file"]{
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  margin-right: 20px;
  
}


textarea {
  resize: vertical;
  
}

.error {
  color: red;
  font-size: 0.875em;
  margin-top: 5px;
}

.button  {
  background-color: #2A5080;
  color: white;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  margin: 20px auto;
  display: block;
  align-items: center;

}

button:hover {
  background-color: #45a049;
}

.submit-button {
  background-color: #2A5080; /* Different background color */
  color: white; /* White text color */
  padding: 10px 24px; /* Slightly larger padding */
  border: none;
  cursor: pointer;
  margin: 20px auto;
  display: block;
  font-size: 16px; /* Larger font size */
  border-radius: 5px; /* Rounded corners */
  transition: background-color 0.3s ease; /* Smooth transition effect */
}

.submit-button:hover {
  background-color: #E64522; /* Hover color */
}