/* Styles for all screen sizes */
.about-container {
    max-width: 100%;
}

.about-content {
    display: flex;
    justify-content: center;
    align-items: center;
}

.about-image {
    width: 100%; /* Adjust image width as needed */
    height: 400px;
    filter: blur(7px);
}

.image-content2 h2 {
    position: absolute;
    top: 45%;
    left: 47%;
    transform: translate(-50%, -50%);
    color: white;
    text-align: center;
    font-size: 30px;
    font-family: 'Inter', sans-serif;
}

.container {
    max-width: 100%;
    margin: 0 auto;
    padding: 20px;
    position: relative;
    animation: slideIn 0.5s ease forwards;
}

.main-image {
    width: 50%; /* Adjust width as needed */
    height: auto;
    float: left; /* Align image to the left */
    margin-left: 40px;
    margin-right: 60px; /* Add some space between image and text */
    margin-top: 30px;
    border-radius: 10%;
    transition: transform 0.3s ease;
}

.text-content h1 {
    margin-bottom: 20px;
    margin-right: 30px;
    font-size: 40px;
    color: brown;
}

.text-content p {
    margin-bottom: 20px;
    margin-right: 120px;
    color: black;
    font-size: 20px;
}

.container1 {
    max-width: 100%;
    margin: 0 auto;
    padding: 20px;
    position: relative;
    animation: slideIn 0.5s ease forwards;
}

.about-content1 {
    display: flex;
    justify-content: center;
    align-items: center;
}

.main-image1 {
    width: 100%;
    height: auto;
    float: right; /* Align image to the right */
    margin-left: 50px; /* Add some space between text and image */
    margin-right: 140px; /* Add some space between image and container edge */
    border-radius: 10%;
    transition: transform 0.3s ease;
    margin-top: 40px;
}

.text-content1 {
    width: 50%; /* Adjust width as needed */
    float: left; /* Align text to the left */
    margin-left: 50px; /* Add some space between text and image */
    margin-right: 100px;
    margin-top: 50px;
    animation-name: slideInFromLeft;
}

.text-content1 h1 {
    margin-bottom: 20px;
    font-size: 40px;
    color: brown;
}

.text-content1 p {
    margin-bottom: 20px;
    color: black;
    font-size: 20px;
}

.main-image:hover,
.main-image1:hover {
    transform: scale(1.1); /* Scale the image on hover */
}

/* Responsive styles for smaller screens */
@media (max-width: 768px) {
    .about-content,
    .about-content1 {
        flex-direction: column;
        align-items: center;
    }

    .main-image,
    .main-image1 {
        width: 100%;
        float: none; /* Reset float for smaller screens */
        margin: 0;
        margin-bottom: 20px; /* Add space below images */
    }

    .text-content,
    .text-content1 {
        width: 90%;
        float: none; /* Reset float for smaller screens */
        margin: 0;
        text-align: center;
    }

    .text-content h1,
    .text-content1 h1 {
        font-size: 30px; /* Adjust font size for smaller screens */
    }

    .text-content p,
    .text-content1 p {
        font-size: 18px; /* Adjust font size for smaller screens */
    }
}

.footer {
    background-color: #333;
    color: white;
    padding: 5px;
    text-align: center;
    margin-top: 5px;
  }
  
  .footer-content {
    display: flex;
    flex-direction: column;
    align-items: center;
   
  }
  
  .footer-links {
    margin-top: 5px;
    
  }
  
  .footer-links a {
    color: white;
    margin: 0 5px;
    text-decoration: none;
    margin-bottom: 10px;
  }
  
  .footer-links a:hover {
    text-decoration: underline;
  }
  
  
  
