/* Default styles remain unchanged */

.profile-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 87vh;
  background-color: #f0f0f0;
}

.profile-box {
  display: flex;
  width: 70%;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  height: 450px;
}

.profile-left {
  flex: 1;
  background-color: #264f7aee;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
}

.profile-image {
  width: 170px;
  height: 170px;
  border-radius: 50%;
  margin-bottom: 20px;
}

.profile-name {
  font-size: 24px;
  font-weight: bold;
  color: #ffffff;
}

.profile-right {
  flex: 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px;
}

.profile-info {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  margin-left: 30px;
}

.profile-info label {
  font-weight: bold;
  width: 190px;
  font-size: 20px;
}

.profile-info span {
  font-size: 20px;
}

.edit-profile-button {
  padding: 10px 20px;
  font-size: 16px;
  font-weight: bold;
  color: #fff;
  background-color: #1f4670;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  align-self: flex-start;
  margin-top: 30px;
  margin-left: 170px;
}

.edit-profile-button:hover {
  background-color: #0056b3;
}

/* Responsive styles */

@media (max-width: 1200px) {
  .profile-box {
    width: 80%;
  }

  .profile-info {
    margin-left: 20px;
  }

  .edit-profile-button {
    margin-left: 120px;
  }
}

@media (max-width: 992px) {
  .profile-box {
    width: 70%;
    height: auto;
    flex-direction: column;
  }

  .profile-left {
    width: 100%;
    padding: 10px;
    height: auto;
  }

  .profile-image {
    width: 150px;
    height: 150px;
  }

  .profile-name {
    font-size: 20px;
  }

  .profile-right {
    width: 100%;
    padding: 10px;
  }

  .profile-info {
    margin-left: 0;
    flex-direction: column;
  }

  .profile-info label, .profile-info span {
    width: auto;
    font-size: 18px;
  }

  .edit-profile-button {
    margin-left: 0;
    align-self: center;
  }
}

@media (max-width: 768px) {
  .profile-image {
    width: 120px;
    height: 120px;
  }

  .profile-name {
    font-size: 18px;
  }

  .profile-info label, .profile-info span {
    font-size: 16px;
  }

  .edit-profile-button {
    padding: 8px 16px;
    font-size: 14px;
    margin-top: 20px;
  }
}

@media (max-width: 576px) {
  .profile-image {
    width: 50px;
    height: 50px;
  }

  .profile-name {
    font-size: 16px;
  }

  .profile-info label, .profile-info span {
    font-size: 14px;
  }

  .edit-profile-button {
    padding: 6px 12px;
    font-size: 12px;
    margin-top: 15px;
  }
}
