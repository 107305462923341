/* addscholarship.css */

/* Reset default margin and padding */
html, body {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
}

/* Dashboard container */
.add-scholarship-dashboard {
    display: flex;
}

/* Sidebar/menu container */
.menu-container {
    width: 60px;
    height: 100vh;
    background-color: #2A5080;
    color: #fff;
    padding: 20px 10px;
    box-sizing: border-box;
    position: fixed;
    transition: width 0.3s, border-radius 0.3s;
    border-top-right-radius: 30px;
    border-bottom-right-radius: 30px;
    overflow: hidden;
    cursor: pointer;
}

.menu-container.expanded {
    width: 200px;
    border-radius: 0;
}

/* Logo container */
.logo-container {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.app-logo {
    width: 30px;
    height: 30px;
    margin-right: 10px;
}

.app-name {
    font-size: 1.5rem;
}

/* Menu options */
.menu-options {
    margin-top: 20px;
}

.menu-list {
    list-style-type: none;
    padding: 0;
}

.menu-item {
    padding: 20px 0;
    cursor: pointer;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 18px;
}

.menu-item:hover {
    background-color: #555;
}

.menu-item.logout {
    color: white;
}

/* Main content area */
.main-content {
    flex: 1;
    padding: 20px;
    margin-left: 60px;
    transition: margin-left 0.3s ease;
}

.main-content.menu-expanded {
    margin-left: 200px;
}

/* Top bar (header) */
.top-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.icons {
    display: flex;
    gap: 10px;
}

.icon {
    font-size: 24px;
    cursor: pointer;
}

.icon:hover {
    color: #007bff;
}

.welcome {
    font-size: 24px;
    margin-bottom: 20px;
    margin-top: 10px;
}


/* Apply container (form area) */
.apply-container {
    margin-top: 10px;
}

.scholarship-info {
    background-color: #fdebdc;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border: 2px solid #829bb9;
    transition: transform 0.3s ease-in-out;
}

.scholarship-info:hover {
    transform: scale(1.01); /* Slight zoom effect on hover */
}

.scholarship-info h2 {
    font-size: 14px;
}

.scholarship-info h3 {
    font-size: 14px;
    margin-bottom: 5px;
    margin-left: 10px;
}

/* Apply button */
.apply-button5 {
    background-color: #0a2749;
    color: white;
    border: none;
    padding: 10px 15px;
    cursor: pointer;
    font-size: 16px;
    border-radius: 5px;
    margin-top: 20px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    transition: background-color 0.3s, transform 0.3s
}

.apply-button5:hover {
    background-color: #1E3B65; /* Slightly darker blue on hover */
    transform: translateY(-3px); /* Slight lift effect */
}

/* Message area */
.message {
    margin-top: 10px;
    font-size: 1rem;
    color: green;
}

/* Styling for input fields */
.scholarship-info input[type="text"],
.scholarship-info textarea {
    width: calc(100% - 40px); /* Adjust width to leave space for padding */
    padding: 20px;
    font-size: 1rem;
    border: 1px solid #8fa5be;
    border-radius: 5px;
    box-sizing: border-box;
    margin-top: 5px; /* Adjust margin to separate fields */
    margin-bottom: 5px;
    transition: border-color 0.3s;
}

.scholarship-info input[type="text"]{
    width: calc(100% - 40px); /* Adjust width to leave space for padding */
    padding: 10px;
    border-color: #8fa5be;
}

/* Additional styling for input groups */
.row {
    display: flex;
    gap: 20px;
    margin-bottom: 10px;
}

.input-group {
    flex: 1;
    
}

.input-group h3 {
    display: block;
    font-size: 14px;
    
}

.input-group input[type="text"]
{
    padding: 10px;
}