html, body {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
  }
  
  .privacy-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f4f4f4;
    padding: 20px;
  }
  
  .privacy-box {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    max-width: 900px;
    width: 100%;
    max-height: 80vh; /* Reduced height */
    overflow-y: auto; /* Scrollable content */
  }
  
  .privacy-box h2 {
    margin-bottom: 20px;
    font-size: 24px;
    color: #333;
  }
  
  .privacy-box h3 {
    margin-top: 20px;
    font-size: 18px;
    color: #555;
  }
  
  .privacy-box p {
    margin: 10px 0;
    line-height: 1.6;
    color: #666;
  }
  
  .ok-button {
    display: block;
    margin: 20px auto 0;
    padding: 10px 20px;
    background-color: #4CAF50;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease;
  }
  
  .ok-button:hover {
    background-color: #45a049;
  }
  