/* scholarshiphistory.css */

.history_container {
  display: flex;
  flex-direction: column;
  align-items: left;
  padding: 20px;
  background-color: #f4f4f4;
}

h2 {
  margin-bottom: 20px;
  font-size: 24px;
  color: #333;
}

.application_card {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  padding: 20px;
  width: 100%;
  max-width: 1200px;
}

.application_card h3 {
  margin-top: 0;
  font-size: 20px;
  color: #007BFF;
}

.application_card p {
  margin: 8px 0;
  font-size: 16px;
  color: #555;
}

.application_card p strong {
  color: #333;
}

.row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.row p {
  flex: 1;
  margin-right: 20px;
}

@media (max-width: 768px) {
  .row {
      flex-direction: column;
  }

  .row p {
      margin-right: 0;
      margin-bottom: 10px;
  }
}

/* Status container and status classes */
.status_container {
  margin-top: 10px;
}

.status_container div {
  margin-top: 5px;
}

.approved {
  color: green;
}

.rejected {
  color: red;
}

.pending {
  color: blue;
}
