html, body {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    font-family: Arial, sans-serif;
  }
  
  .available-scholarship-dashboard {
    display: flex;
    min-height: 100vh;
  }
  
  .menu-expanded .main-content {
    margin-left: 200px;
  }
  
  .main-content {
    flex: 1;
    padding: 20px;
    margin-left: 60px;
    transition: margin-left 0.3s ease;
  }
  
  .top-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .welcome {
    font-size: 24px;
    font-weight: bold;
  }
  
  .icons {
    display: flex;
    gap: 10px;
  }
  
  .icon {
    font-size: 24px;
    cursor: pointer;
  }
  
  .icon:hover {
    color: #007bff;
  }
  
  .state-select {
    margin-bottom: 20px;
  }
  
  .state-select label {
    font-weight: bold;
    margin-right: 10px;
  }
  
  .state-select select {
    padding: 5px;
    font-size: 16px;
  }
  
  .scholarship-list {
    margin-top: 20px;
  }
  
  .scholarship-item {
    background-color: #f9f9f9;
    padding: 15px;
    border: 1px solid #ddd;
    border-radius: 5px;
    margin-bottom: 15px;
  }
  
  .scholarship-item h3 {
    margin-top: 0;
    margin-bottom: 10px;
  }
  
  .scholarship-item p {
    margin: 0;
    font-size: 14px;
    color: #555;
  }
  
  @media (max-width: 768px) {
    .main-content {
      padding: 10px;
    }
    
    .state-select select {
      width: 100%;
      margin-top: 5px;
    }
  
    .scholarship-item {
      padding: 10px;
    }
  
    .scholarship-item h3 {
      font-size: 18px;
    }
  }
  