html, body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
}

.admin-dashboard {
  display: flex;
}

.menu-container {
  width: 60px;
  height: 100vh;
  background-color: #2A5080;
  color: #fff;
  padding: 20px 10px;
  box-sizing: border-box;
  position: fixed;
  transition: width 0.3s, border-radius 0.3s;
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
  overflow: hidden;
  cursor: pointer;
}

.menu-container.expanded {
  width: 200px;
  border-radius: 0;
}

.logo-container {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.app-logo {
  width: 30px;
  height: 30px;
  margin-right: 10px;
}

.app-name {
  font-size: 1.5rem;
}

.menu-options {
  margin-top: 20px;
}

.menu-list {
  list-style-type: none;
  padding: 0;
}

.menu-item {
  padding: 20px 0;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.menu-item:hover {
  background-color: #555;
}

.menu-item.logout {
  color: white;
}

.main-content {
  flex: 1;
  padding: 20px;
  margin-left: 60px;
  transition: margin-left 0.3s ease;
}

.main-content.menu-expanded {
  margin-left: 200px;
}

.top-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.icons {
  display: flex;
  gap: 10px;
}

.icon {
  font-size: 24px;
  cursor: pointer;
}

.icon:hover {
  color: #007bff;
}

.welcome {
  font-size: 24px;
  margin-bottom: 20px;
  margin-top: 10px;
}
.verification-requests-list {
  margin-top: 20px;
}

.verification-request-box {
  border: 1px solid #dee2e6;
  border-radius: 5px;
  padding: 15px;
  margin-bottom: 15px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  background-color: #fff;
}

.verification-request-box p {
  margin: 5px 0;
}

.verification-request-box button {
padding: 8px 12px;
margin-right: 10px; /* Add margin-right for spacing between buttons */
background-color: #007bff;
color: #fff;
border: none;
border-radius: 3px;
cursor: pointer;
}

.verification-request-box button:hover {
background-color: #0056b3;
}