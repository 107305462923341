html, body {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
}

.menu-container {
    width: 60px;
    height: 100vh; /* Ensures the container covers the full viewport height */
    background-color: #2A5080;
    color: #fff;
    padding: 20px 10px; /* Adjust padding to fit the new width */
    box-sizing: border-box;
    position: fixed; /* Keeps the menu fixed on the side */
    transition: width 0.3s, border-radius 0.3s;
    border-top-right-radius: 30px;
    border-bottom-right-radius: 30px;
    overflow: hidden;
    cursor: pointer;
}

.menu-container.expanded {
    width: 200px;
    border-radius: 0;
}

.logo-container {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.app-logo {
    width: 30px; /* Adjust the logo size here */
    height: 30px;
    margin-right: 10px;
}

.app-name {
    font-size: 1.5rem;
}

.menu-options {
    margin-top: 20px;
}

.menu-list {
    list-style-type: none;
    padding: 0;
}

.menu-item {
    padding: 20px 0;
    cursor: pointer;
    white-space: nowrap; /* Prevent text wrapping */
    overflow: hidden;
    text-overflow: ellipsis;
}

.menu-item:hover {
    background-color: #555;
}

.menu-item.logout {
    color: white; /* Optional: style the logout item differently */
}

@media (max-width: 768px) {
    .menu-container {
        width: 50px;
    }

    .menu-container.expanded {
        width: 150px;
    }

    .app-logo {
        width: 25px;
        height: 25px;
    }

    .app-name {
        font-size: 1.2rem;
    }
}
