/* Set basic styles for full-page responsiveness */
@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@400;500;700&display=swap');

html, body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
}
.video-container {
  position: relative;
  width: 100%;
  height: 500px;
  overflow: hidden;
}

video {
  width: 100%;
  height: auto;
}

.video-content {
  position: absolute;
  top: 50%;
  left: 25%;
  transform: translate(-50%, -50%);
  text-align: left;
  color: rgb(255, 255, 255);
  font-size: 18px;
  font-family: "Inter",sans-serif;
  font-weight: bold;
}

.video-content p{
  text-align: center;
  color: rgb(109, 6, 6);
  font-size: 18px;
  font-family: "Inter",sans-serif;
  font-weight: bold;
  margin-bottom: 40px;
}

.home-container {
  width: 100%;
  height: 100%;
}

.content {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100% - 80px); 
}

.image-container {
  position: relative;
  width: 100%;
  max-height: 500px;
}

.image-container img {
  width: 100%;
  height: 500px; 
  
}

.image-content {
  position: absolute;
  top: 50%;
  left: 8%; 
  transform: translateY(-50%);
  color: brown;
  text-align: left;
  width: 40%; 
  
}

.image-content h2 {
  margin-bottom: 10px;
  color: blue;
}

.dropdowns {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.horizontal-dropdowns {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}

.dropdown {
  display: flex;
  flex-direction: column;
  width: 100%; /* Make it responsive to container width */
}

.dropdown label {
  color: #ffffff;
  margin-bottom: 10px;
  font-weight: normal;
  font-size: 16px;
}

.dropdown select {
  height: 40px;
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border-radius: 10px;
  border-color: #000;
}

.dropdownn {
  display: flex;
  flex-direction: column;
  width: 100%; /* Make it responsive to container width */
  margin-left: 29px;
  margin-top: 10px;
}

.dropdownn label {
  color: #000000;
  margin-bottom: 10px;
  font-weight: bold;
  font-size: 16px;
}

.dropdownn select {
  height: 40px;
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border-radius: 10px;
  border-color: #000;
}

.dropdowni {
  display: flex;
  flex-direction: column;
  width: 100%; /* Make it responsive to container width */
  margin-left: 6px;
  margin-top: 10px;
}

.dropdowni label {
  color: #000000;
  margin-bottom: 10px;
  font-weight: bold;
  font-size: 16px;
}

.dropdowni select {
  height: 40px;
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border-radius: 10px;
  border-color: #000;
}


/*.buttons {
  margin-top: 10px;
}

.buttons .btn {
  padding: 10px 20px;
  margin-right: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.buttons .btn:last-child {
  margin-right: 0;
}*/

.buttons4 {
  margin-top: 50px;
}

.btn4 {
  margin-right: 10px;
  background-color: #3f4997;
  color: #ffffff;
  border: none;
  padding: 20px 30px;
  border-radius: 30px; 
  cursor: pointer;
  font-size: 16px;
  width: 300px;
  font-weight:normal;
  margin-left: 140px;
}

.btn4:hover {
  background-color: #1f2c91; 
}

.slider{
  max-width: 100%;
}

.homepageBanner {
  float: left;
  width: 100%;
  margin-top: 0px;
  display: block;
}

.additional-container6 {
  margin-top: 20px;
  margin-left: 60px; 
  margin-right: 60px; 
  margin-bottom: 20px; 
}

.box-frame {
  border: 2px solid #ccc; 
  padding: 20px; 
  display: -webkit-flex;
    display: -moz-box;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    background: #2A5080;
    border-radius: 15px;
    box-shadow: 0 5px 5px 0 rgba(0, 0, 0, .1607843137);
}

.left-side {
  width: 33%; 
  margin-left: 30px;
}

.notification-list {
  padding: 10px;
  background-color: #fff;
  box-shadow: 0 0 9px -1px rgba(0, 0, 0, .2);
  border-radius: 8px ;
  background-clip: border-box;
  overflow: hidden;
  position: relative; 
  height: 300px; 
}

.notification-list p {
  position: relative; 
  padding-left: 20px; 
}

.notification-list p::before {
  content: "\2022"; 
  position: absolute;
  left: 0;
  color: blue; 
}


.noticeBoard_marquee__2rfdx {
  position: absolute;
  list-style: none;
  padding: 2px 8px;
  text-align: justify !important;
  animation: noticeBoard_marquee__2rfdx 20s linear infinite;
  bottom: 0; 
}
@keyframes noticeBoard_marquee__2rfdx {
  0% {
    top: 100%; 
  }
  100% {
    top: -100%; 
  }
}

.centered {
  text-align: center;
  font-size: 30px;
}

.left-side h5 {
  margin: 0 auto; 
  text-align: center;
  font-size: 24px;
  margin-bottom: 20px;
  color: rgb(253, 253, 253);
}
.right-side1 {
  flex: 1; 
  min-width: 50%; 
  margin-left: 50px;
  overflow: hidden; 
}

.scholarship-headingi {
  text-align: center;
  font-size: 30px;
  color: rgb(255, 255, 255);
  margin-top: -42px;
  margin-bottom: 30px;

}


.right-side h5 {
  margin: 0 auto; 
  text-align: center; 
  font-size: 24px;
  
  
}

.slider-containeri {
  display: flex;
  flex-direction: row; 
  animation: scroll 20s linear infinite;
  margin-top: -30px;
}

.slider-item {
  min-width: 200px; 
  background-color: #ffffff;
  padding: 10px;
  border-radius: 5px;
}

@keyframes scroll {
  0% {
    transform: translateX(100%); 
  }
  100% {
    transform: translateX(-100%);
  }
}

.scholarship-boxe {
  width: 250px;
  border: 4px solid #fdfdfd;
  border-radius: 5px;
  padding: 10px;
  margin-right: 20px;
  margin-top: 10px;
  height: 287px;
}

.scholarship-boxe img {
  width: 130px;
  height: 130px;
  margin-bottom: 10px;
}

.scholarship-details h6 {
  margin: 0;
  margin-bottom: 5px;
  color: #fff;
}

.scholarship-details p {
  margin: 0;
  margin-bottom: 1px;
  color: #fff;
}



.four-box-container2 {
  margin-left: 0;
  margin-right: 0;
  height: 520px;
  margin-top: 50px;
  text-align: center;
  font-size: 25px;
}

.box-frame4 {
  border: 2px solid #ccc; 
  padding: 30px; 
  display: flex;
  background: #aad0f3;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, .1607843137); 
  margin-left: 0;
  margin-right: 0; 
  height: 150px;
}



.box1 {
  flex: 1;
  height: 350px;
  margin: 0 10px;
  margin-top: 50px;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #ccc;
  border-radius: 10px;
  position: relative; /* Needed to position the circle */
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, .1607843137); 
}

/* Styles for the circle with numbers */
.box1::before {
  content: attr(data-number);
  position: absolute;
  top: -15px;
  left: 50%;
  transform: translateX(-50%);
  width: 30px;
  height: 30px;
  background-color: #fff;
  border: 2px solid #ccc;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 14px;
  color: #000;
}


.box-content3 {
  text-align: center;
}


.box1 img {
  max-width: 50%; 
  max-height: 50%; 
  margin-top: 20px;
}

.box1 p {
  margin-top: 20px;
  font-size: 18px;
  font-family: 'Inter Light',sans-serif;
}

.box1 span {
  font-size: 14px;
  font-family: 'Inter ExtraLight',sans-serif;
}

.link-box-container {
  margin: 40px 0;
  height: 800px;
  margin-bottom: -110px;
}

.link-box-container h3{
  text-align: center;
  font-size: 26px;
}

.link-box-frame {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  gap: 20px;
  margin-top: 50px;
  margin: 0px 100px;
  
}

.link-box {
  flex: 1 1 22%;
  max-width: 20%;
  border: 1px solid #ccc;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.1);
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  box-sizing: border-box;
  margin-top: 20px;
}

.link-box img {
  max-width: 100%;
  max-height: 150px;
  margin: 0 auto 10px; /* Center the image horizontally and add space below */
  display: block;
}

.link-box p {
  margin: 10px 0 0;
  font-size: 16px;
  color: #333;
  text-align: center; /* Ensure text is centered */
}

@media only screen and (max-width: 768px) {
  .image-content {
    left: 2%;
    width: 96%; 
    text-align: center; 
  }

  .image-container {
    max-height: 300px; 
  }

  
}

@media only screen and (max-width: 768px) {
  .image-content {
    left: 2%; 
    width: 96%; 
    text-align: center; 
  }

  .image-container {
    max-height: 300px; 
  }

  .horizontal-dropdowns {
    flex-direction: row; 
  }

  .dropdown {
    width: 50%;
  }

  .left-side {
    width: 100%; 
    margin-top: 20px; 
  }
}


.footer {
  background-color: #000000;
  color: white;
  padding: 5px;
  text-align: center;
  margin-top: 5px;
}

.footer-content {
  display: flex;
  flex-direction: column;
  align-items: center;
 
}

.footer-links {
  margin-top: 5px;
  
}

.footer-links a {
  color: white;
  margin: 0 5px;
  text-decoration: none;
  margin-bottom: 10px;
}

.footer-links a:hover {
  text-decoration: underline;
}


