/* Container Styling */
.view-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 20px;
  box-sizing: border-box;
  width: 100%;
  background-color: #ffffff; /* White background for the container */
  border: 1px solid #ddd;
  border-radius: 8px;
}

/* Criteria and Scholarships Styling */
.criteria-and-scholarships, 
.featured-scholarships {
  width: 100%;
  margin-bottom: 20px;
}

.selected-criteria, 
.scholarships-list,
.featured-scholarships {
  width: 100%;
}

/* Scholarship Box Styling */
.scholarship-box1 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  border: 1px solid #2e2c7a;
  padding: 20px;
  margin-bottom: 30px;
  background-color: #fff;
  box-sizing: border-box;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.scholarship-box1:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.scholarship-box1 img {
  width: 100px;
  height: 100px;
  margin-right: 20px;
  margin-bottom: 10px;
  border-radius: 50%;
}

.scholarship-detailss {
  width: 100%;
}

.scholarship-detailss h6 {
  margin: 0 0 10px;
  font-size: 18px;
}

.apply-button1 {
  display: inline-block;
  padding: 8px 16px;
  background-color: #007bff;
  color: #fff;
  text-decoration: none;
  border-radius: 4px;
  margin-top: 10px;
  transition: background-color 0.3s ease;
}

.apply-button1:hover {
  background-color: #0056b3;
}

/* Featured Scholarships Styling */
.featured-scholarships h3 {
  margin-bottom: 20px;
}

.scroll-container {
  max-height: 400px;
  overflow-y: auto;
  border: 1px solid #ddd;
  padding: 10px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.featured-scholarship-box {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border: 1px solid #ddd;
  padding: 10px;
  margin-bottom: 20px;
  background-color: #fff;
  box-sizing: border-box;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.featured-scholarship-box:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15);
}

.featured-scholarship-details {
  width: 100%;
}

/* Responsive Styling */
@media (min-width: 768px) {
  .view-container {
    flex-wrap: nowrap;
  }

  .criteria-and-scholarships {
    width: 75%;
  }

  .featured-scholarships {
    width: 23%;
  }

  .scholarship-box1, 
  .featured-scholarship-box {
    flex-direction: row;
    align-items: center;
  }

  .scholarship-box1 img {
    margin-bottom: 0;
  }

  .featured-scholarship-box {
    flex-direction: row;
    align-items: center;
  }
}
