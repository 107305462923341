/* Set basic styles for full-page responsiveness */
html, body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
}

.landing-container {
  width: 100%;
  height: 100%;
}

.content {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100% - 80px); 
}

.video-container {
  position: relative;
  width: 100%;
  height: 500px;
  overflow: hidden;
}

img {
  width: 100%;
  height: auto;
  height: 120%
}

.image-content p{
  position: relative;
  top: 100%;
  left: 110%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: rgb(24, 24, 24);
  z-index: 10; /* Ensure it's above the video */

}

.image-content h3{
  position:relative;
  top: 100%;
  left: 110%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: rgb(33, 35, 126);
  z-index: 10; /* Ensure it's above the video */
  margin-bottom: 50px;
  margin-top: 90px;
}

.search-bar {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
}

.search-bar input[type="text"] {
  width: 300px;
  padding: 10px;
  border: 2px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
}

.search-bar button {
  padding: 10px 20px;
  margin-left: 10px;
  border: none;
  background-color: #007bff;
  color: white;
  font-size: 16px;
  border-radius: 4px;
  cursor: pointer;
}

.search-bar button:hover {
  background-color: #0056b3;
}

.image-container {
  position: relative;
  width: 100%;
  max-height: 500px;
}

.image-container img {
  width: 100%;
  height: 500px; 
}

.image-content {
  position: absolute;
  top: 50%;
  left: 8%; 
  transform: translateY(-50%);
  color: brown;
  text-align: left;
  width: 40%; 
}

.image-content h2 {
  margin-bottom: 10px;
  color: blue;
}

.dropdowns {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.horizontal-dropdowns {
  display: flex;
  gap: 20px;
}

.dropdown {
  display: flex;
  flex-direction: column;
  width: 50%; 
}

.dropdown label {
  font-weight: bold;
  color: black;
  margin-bottom: 10px;
}

.dropdown select {
  height: 40px;
}

.buttons {
  margin-top: 30px;
}

.btn {
  margin-right: 10px;
  background-color: #142CDC;
  color: #000;
  border: none;
  padding: 20px 30px;
  border-radius: 30px; 
  cursor: pointer;
  font-size: 15px;
}

.btn:hover {
  background-color: #142CDC; 
}

.slider {
  max-width: 100%;
}

.homepageBanner {
  float: left;
  width: 100%;
  margin-top: 0px;
  display: block;
}

.additional-container1 {
  margin-top: 20px;
  margin-left: 60px; 
  margin-right: 60px; 
  margin-bottom: 20px; 
}

.box-frame0 {
  border: 2px solid #ccc; 
  padding: 20px; 
  display: -webkit-flex;
    display: -moz-box;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    background: #2A5080;
    border-radius: 15px;
    box-shadow: 0 5px 5px 0 rgba(0, 0, 0, .1607843137);
}


@keyframes noticeBoard_marquee__2rfdx {
  0% {
    top: 100%; 
  }
  100% {
    top: -100%; 
  }
}

.centered {
  text-align: center;
  font-size: 30px;
}



.right-side {
  flex: 1; 
  min-width: 80%; 
  margin-left: 50px;
  overflow: hidden; 
  padding: 40px;
}

.scholarship-heading {
  text-align: center;
  font-size: 30px;
  color: rgb(255, 255, 255);
}

.right-side h5 {
  margin: 0 auto; 
  text-align: center; 
  font-size: 24px;
  margin-bottom: 20px;
  
}

.slider-container1 {
  display: flex;
  flex-direction: row; 
  animation: scroll 30s linear infinite;
  margin-top: 30px;
}

.slider-item1 {
  min-width: 200px; 
  background-color: #f0f0f0;
  padding: 10px;
  border-radius: 5px;
}

@keyframes scroll {
  0% {
    transform: translateX(100%); 
  }
  100% {
    transform: translateX(-100%);
  }
}

.scholarship-box {
  width: 200px;
  border: 2px solid #ffffff;
  border-radius: 5px;
  padding: 10px;
  margin-right: 20px;
  margin-top: 10px;
  height: 287px;
}

.scholarship-box img {
  width: 200px;
  height: 150px;
  margin-bottom: 10px;
}

.scholarship-details h6 {
  margin: 0;
  margin-bottom: 20px;
  font-size: 18px;
}

.scholarship-details p {
  margin: 0;
  margin-bottom: 45px;
  color: white;
}


.four-box-container5 {
  margin-left: 0;
  margin-right: 0;
  height: 520px;
  margin-top: 50px;
  text-align: center;
  font-size: 25px;
}

.box-frame2 {
  border: 2px solid #ccc; 
padding: 30px; 
  display: flex;
  background: #2A5080;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, .1607843137); 
  margin-left: 0;
  margin-right: 0; 
  height: 150px;
}

.box6 {
  flex: 1;
  height: 350px;
  margin: 0 10px;
  margin-top: 50px;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #ccc;
  border-radius: 10px;
  position: relative; /* Needed to position the circle */
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, .1607843137); 
}

/* Styles for the circle with numbers */
.box6::before {
  content: attr(data-number);
  position: absolute;
  top: -15px;
  left: 50%;
  transform: translateX(-50%);
  width: 30px;
  height: 30px;
  background-color: #fff;
  border: 2px solid #ccc;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 14px;
  color: #000;
}

.box-content8 {
  text-align: center;
}

.box6 img {
  max-width: 50%; 
  max-height: 50%; 
  margin-top: 20px;
}

.box6 p {
  margin-top: 20px;
  font-size: 18px;
  font-family: 'Inter Light', sans-serif;
}

.box6 span {
  font-size: 14px;
  font-family: 'Inter ExtraLight', sans-serif;
}

.link-box-container {
  margin: 40px 0;
  height: 800px;
}

.link-box-container h3 {
  text-align: center;
  font-size: 26px;
}

.link-box-frame {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  gap: 20px;
  margin-top: 50px;
  margin: 0px 50px;
  margin-bottom: 10px;
}

.link-box {
  flex: 1 1 22%;
  max-width: 40%;
  border: 1px solid #0c0c0c;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.1);
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  box-sizing: border-box;
  margin-top: 20px;
}

.link-box img {
  max-width: 100%;
  max-height: 150px;
  margin: 0 auto 10px; /* Center the image horizontally and add space below */
  display: block;
}

.link-box p {
  margin: 10px 0 0;
  font-size: 16px;
  color: #333;
  text-align: center; /* Ensure text is centered */
}

@media only screen and (max-width: 768px) {
  .image-content {
    left: 2%;
    width: 96%; 
    text-align: center; 
  }

  .image-container {
    max-height: 300px; 
  }
}

@media only screen and (max-width: 768px) {
  .image-content {
    left: 2%; 
    width: 96%; 
    text-align: center; 
  }

  .image-container {
    max-height: 300px; 
  }

  .horizontal-dropdowns {
    flex-direction: row; 
  }

  .dropdown {
    width: 50%;
  }

 
}

/* About Us Slider */
.about-us-slider {
  margin: 50px 60px;
  padding: 20px;
  background-color: #e2f1ff;
  border-radius: 15px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.15);
  overflow: hidden; /* Ensure content stays within the box */
}

.about-us-slider h3 {
  text-align: center;
  font-size: 28px;
  color: #333;
  margin-bottom: 30px;
}

.slider-container {
  display: flex;
  position: relative;
  width: 100%; /* Ensure container width fits within the parent box */
  overflow: hidden; /* Hide overflow */
}

.slider-container::before, 
.slider-container::after {
  content: '';
  position: absolute;
  width: 50px;
  height: 100%;
  z-index: 1;
  top: 0;
}

.slider-container::before {
  left: 0;
  background: linear-gradient(to right, #f7f7f7 0%, transparent 100%);
}

.slider-container::after {
  right: 0;
  background: linear-gradient(to left, #f7f7f7 0%, transparent 100%);
}

.slider-item {
  flex: 4 0 100%;
  max-width: 33.33%;
  box-sizing: border-box;
  padding: 20px;
  text-align: center;
  background-color: white;
  border-radius: 10px;
  margin: 0; /* Remove margin to avoid overflow */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.slider-item h4 {
  font-size: 22px;
  color: #007bff;
  margin-bottom: 15px;
}

.slider-item p {
  font-size: 16px;
  color: #555;
  line-height: 1.6;
}

/* Animation for sliding effect */
@keyframes scroll1 {
  0% { transform: translateX(0); }
  100% { transform: translateX(calc(-33.33% * 3)); } /* Adjust based on item width */
}

.slider-container {
  animation: scroll 30s linear infinite;
}


.footer {
  background-color: #000000;
  color: white;
  padding: 5px;
  text-align: center;
  margin-top: -120px;
}

.footer-content {
  display: flex;
  flex-direction: column;
  align-items: center;

 
}



.footer-links {
  margin-top: 5px;
  
}

.footer-links a {
  color: white;
  margin: 0 5px;
  text-decoration: none;
  margin-bottom: 10px;
}

.footer-links a:hover {
  text-decoration: underline;
}

