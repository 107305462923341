
html, body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  font-family: Arial, sans-serif;
}

.student-history-container {
  display: flex;
  height: 100vh;
  background-color: #ffff;
}

/* Sidebar/menu styles */
.menu-container {
  width: 60px;
  height: 100%;
  background-color: #2A5080;
  color: #fff;
  padding: 20px 10px;
  box-sizing: border-box;
  position: fixed;
  transition: width 0.3s, border-radius 0.3s;
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
  overflow: hidden;
  cursor: pointer;
}

.menu-container.expanded {
  width: 200px;
  border-radius: 0;
}

/* Main content area */
.main-content {
  flex: 1;
  padding: 20px;
  margin-left: 60px;
  transition: margin-left 0.3s ease;
}

.main-content.menu-expanded {
  margin-left: 200px;
}

/* Top bar (header) */
.top-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.icons {
  display: flex;
  gap: 10px;
}

.icon {
  font-size: 24px;
  cursor: pointer;
}

.icon:hover {
  color: #007bff;
}

.welcome {
  font-size: 24px;
  margin-bottom: 20px;
  margin-top: 10px;
}

/* Table styling */
table {
  width: 100%;
  border-collapse: collapse;
  background-color: #ffffff;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  overflow: hidden;
  transition: box-shadow 0.3s ease;
}

table:hover {
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.2);
}

th, td {
  padding: 12px;
  text-align: left;
  border-bottom: 1px solid #ddd;
  transition: background-color 0.3s ease;
}

th {
  background-color: #2A5080;
  color: #fff;
  text-transform: uppercase;
}

tr:hover td {
  background-color: #f1f1f1;
}

p {
  font-size: 18px;
  color: #555;
  line-height: 1.6;
}

h2 {
  margin-top: 0;
  margin-bottom: 20px;
  font-size: 18px;
  color: #2A5080;
  font-weight: bold;
  text-transform: uppercase;
  border-bottom: 2px solid #2A5080;
  padding-bottom: 5px;
  margin-right: auto;
  margin-left: auto;
}